.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 5000;
}

.navigation {
  padding: 30px 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background: linear-gradient(
                  to top,
                  rgba(0, 0, 0, 0.0) 0%,
                  rgba(0, 0, 0, 0.2) 20%,
                  rgba(0, 0, 0, 0.5) 50%,
                  rgba(0, 0, 0, 0.8) 80%,
                  rgba(0, 0, 0, 1) 100%
  );

  &__logo {
    width: 247px;
    height: 42px;
  }

  &__dextools {
    height: 30px;
  }

  &__links {
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 40px;

    &__item {
      color: #ffffff;
      cursor: pointer;
      font-weight: 500;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__button {
      margin-left: 40px;
      width: 101px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: 1px solid #ff0202;
      font-weight: 700;
      cursor: pointer;

      a {
        color: #ffffff;
        text-decoration: none;
      }
    }
  }
}

@media (max-width: 1200px) {
  .navigation {
    &__logo {
      width: 151px;
      height: 26px;

      img {
        width: 151px;
        height: 26px;
      }
    }
  }
}

@media (max-width: 950px) {
  .navigation {
    padding: 20px 15px;

    &__links {
      &__item {
        display: none;
      }

      &__button {
        width: 81px;
        height: 26px;
      }
    }
  }
}
