.journey {
  background-image: url('../../assets/image/journey.jpg');
  width: 100%;
  height: 900px;
  background-repeat: no-repeat;
  position: relative;
  background-size: contain;
  white-space: nowrap;
  overflow: hidden;

  @media (max-width: 1024px) {
    height: 450px;
  }

  &__text {
    font-size: 300px;
    font-weight: 700;
    color: #360303;
    margin-left: -480px;
  }

  &__block {
    width: 70%;
    position: absolute;
    top: 68%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__left {
      &__text {
        width: 70%;
        font-size: 40px;
        font-weight: 600;
        line-height: 44px;
        color: #ffffff;
        white-space: normal;
      }

      &__button {
        width: 360px;
        height: 64px;
        background-color: #ff0202;
        color: #ffffff;
        border: none;
        font-size: 20px;
        font-weight: 700;
        margin-top: 60px;
        text-transform: uppercase;

        a {
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
  }
}

@media (max-width: 1502px) {
  .journey {
    &__block {
      &__left {
        &__text {
          width: 80%;
        }
      }
    }
  }
}

@media (max-width: 1398px) {
  .journey {
    &__block {
      &__left {
        &__text {
          font-size: 30px;
        }
      }
      &__right {
        scale: 0.9;
      }
    }
  }
}

@media (max-width: 1299px) {
  .journey {
    &__text {
      font-size: 240px;
    }
    &__block {
      &__left {
        &__text {
          font-size: 28px;
        }
      }
      &__right {
        scale: 0.8;
      }
    }
  }
}

@media (max-width: 1100px) {
  .journey {
    background-size: cover;

    &__text {
      font-size: 150px;
    }
    &__block {
      &__left {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        z-index: 4;
        width: 355px;

        &__text {
          width: 325px;
          text-align: center;
        }

        &__button {
          width: 355px;
          height: 50px;
          font-size: 16px;
          margin-top: 43px;
        }
      }
      &__right {
        bottom: -230px;
        scale: 0.5;
        position: absolute;
        left: 50%;
        transform: translate(-40%);
      }
    }
  }
}
