$steps: 20;

$animation-duration: 1000;
$animation-delay: 5000;
$animation-length: $animation-duration + $animation-delay;

.glitch {
  position: relative;
  margin: 0 auto;
  transform: scale3d(1, 1, 1);

  &__main {
    opacity: 0;
  }

  &__top,
  &__center,
  &__bottom {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    width: 100%;
  }

  &__top {
    animation: glitch-effect-top ($animation-length + ms) infinite linear alternate-reverse;
  }

  &__center {
    animation: glitch-effect-center ($animation-length + ms) infinite linear alternate-reverse;
    left: 3px;
    text-shadow: 2px 0 green;
  }

  &__bottom {
    animation: glitch-effect-bottom ($animation-length + ms) infinite linear alternate-reverse;
  }
}

@function generate-array($length, $range) {
  $array: ();

  @for $i from 0 through $length {
    $value: (($i * 17 + 29) % $range) + 1;
    $array: append($array, $value);
  }

  @return $array;
}

$glitch-from: generate-array($steps, 200);
$glitch-length: generate-array($steps, 25);

$animation-limit: $animation-duration / $animation-length;

@mixin clip($from: 0, $to: 9999px) {
  clip: rect($from, 9999px, $to, 0);
}

@keyframes glitch-effect-top {
  @for $i from 0 through $steps {
    #{percentage($i * (1 / $steps) * $animation-limit)} {
      $index: $i + 1;
      $from: 0;
      $to: nth($glitch-from, $index);
      @include clip($from + px, $to + px);
    }
  }

  #{percentage($animation-limit)} {
    clip: rect(0, 9999px, 9999px, 0);
  }

  100% {
    clip: rect(0, 9999px, 9999px, 0);
  }
}

@keyframes glitch-effect-center {
  @for $i from 0 through $steps {
    #{percentage($i * (1 / $steps) * $animation-limit)} {
      $index: $i + 1;
      $from: nth($glitch-from, $index);
      $to: nth($glitch-from, $index) + nth($glitch-length, $index);
      @include clip($from + px, $to + px);
    }
  }

  #{percentage($animation-limit)} {
    clip: rect(0, 0, 0, 0);
  }

  100% {
    clip: rect(0, 0, 0, 0);
  }
}

@keyframes glitch-effect-bottom {
  @for $i from 0 through $steps {
    #{percentage($i * (1 / $steps) * $animation-limit)} {
      $index: $i + 1;
      $from: nth($glitch-from, $index) + nth($glitch-length, $index);
      $to: 9999;
      @include clip($from + px, $to + px);
    }
  }

  #{percentage($animation-limit)} {
    clip: rect(0, 0, 0, 0);
  }

  100% {
    clip: rect(0, 0, 0, 0);
  }
}

