@use 'sass:math';

.wrapper {
  background-color: #2A0000;
  background-image:
    url(../../assets/image/bg-marquee.svg),
    linear-gradient(180deg, transparent 0%, rgba(black, 0.7) 100%);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.28px;
  text-transform: uppercase;

  @media (max-width: 1024px) {
    font-size: 12px;
    letter-spacing: 0.24px;
  }
}

.list {
  $gap: 24px;
  $gap-mobile: 16px;

  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;

  &__item {
    padding: 11px math.div($gap, 2);

    @media (max-width: 1024px) {
      padding: 9px math.div($gap-mobile, 2);
    }
  }

  &__highlight {
    color: #FF0202;
  }

  &__icon {
    color: #FF0202;
  }
}
