.header {
  background-color: #000000;
  width: 100%;
  height: 1050px;
  position: relative;
  transition: height 0.3s ease;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &/* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  &__container {
    background-image: url('../../assets/image/background1.jpg');
    width: 100%;
    height: 1050px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    position: relative;
    //overflow: hidden;

    &__join {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      gap: 10px;
      width: 500px;
      height: 69px;
      padding: 20px 30px 20px 30px;
      cursor: pointer;
      background: #ff0101;
      border: none;
      box-shadow: 6px 6px 0 0 rgba(0, 0, 0, 0.25);
      color: #ffffff;
      font-family: Chakra Petch, var(--default-font-family);
      font-size: 24px;
      font-weight: 700;
      line-height: 28.8px;
      text-transform: uppercase;
      margin: 40px auto;

      &__focus {
        outline: none;
      }
    }

    &__gradient {
      width: 100%;
      height: 100px;
      background: linear-gradient(
                      to bottom,
                      rgba(0, 0, 0, 0) 10%,
                      rgba(0, 0, 0, 0.2) 20%,
                      rgba(0, 0, 0, 0.5) 50%,
                      rgba(0, 0, 0, 0.8) 80%,
                      rgba(0, 0, 0, 1) 100%
      );
      position: absolute;
      left: 0;
      bottom: 0;
    }

    &__zombies {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 150px;
      width: 100%;

      @media (max-width: 1200px) {
        padding-top: 200px;
      }

      @media (max-width: 1100px) {
        padding-top: 100px;
      }

      @media (max-width: 800px) {
        padding-top: 190px;
      }

      @media (max-width: 600px) {
        display: none;
      }

      &__zombi {
        height: 100%;

        @media (max-width: 1200px) {
          width: 50%;
        }
      }
    }

    &__main {
      position: absolute;
      top: 174px;
      font-size: 70px;
      font-style: normal;
      font-weight: 600;
      line-height: 90%;
      color: #ffebeb;
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;

      &__animated {
        animation: launchText 2s ease-in-out forwards;
      }

      &__notanimated {
        animation: launchTextBack 2s ease-in-out forwards;
      }

      &__text {
        text-align: center;
        text-shadow: 4px 4px 0 rgba(255, 0, 0, 0.4);
        text-transform: uppercase;
      }
    }

    &__scroll {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translate(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &__animated {
        animation: launchScroll 2s ease-in-out forwards;
      }

      &__notanimated {
        animation: launchScrollBack 2s ease-in-out forwards;
      }

      &__text {
        font-weight: 500;
        color: #ff0202;
        text-transform: uppercase;
        margin-top: 20px;
      }
    }
  }

  &__wrapper {
    display: flex;
    justify-content: center;
  }

  &__image {
    width: 100%;
    min-width: 1512px;
    object-fit: cover;
  }

  &__light {
    position: absolute;
    bottom: 87px;
    left: 50%;
    transform: translate(-50%);
    height: 563px;
  }

  &__flash {
    position: absolute;
    bottom: 138px;
    left: 50%;
    transform: translate(-50%);
    height: 482px;
  }

  &__timer {
    position: absolute;
    bottom: -500px;
    left: 50%;
    transform: translate(-50%);

    &__animated {
      animation: launchTimer 2s ease-in-out forwards;
    }

    &__notanimated {
      animation: launchTimerBack 2s ease-in-out forwards;
    }
  }
}

@media (max-width: 1500px) {
  .header {
    &__main {
      font-size: 74px;
      line-height: 70px;
      bottom: 990px;
    }
  }
}

@media (max-width: 1300px) {
  .header {
    &__container {
      &__main {
        font-size: 50px;
        line-height: 46px;
        bottom: 1070px;
      }
    }
  }
}

@media (max-width: 1100px) {
  .header {
    height: 100vh;

    &__container {
      max-height: 100vh;
      &__join {
        padding: 10px 15px;
        width: 400px;
        height: auto;
        font-size: 18px;
      }
      &__scroll {
        bottom: 20px;

        &__animated {
          animation: none;
        }

        &__notanimated {
          animation: none;
        }

        img {
          width: 12px;
          height: 31px;
        }

        &__text {
          display: none;
        }
      }

      &__main {
        font-size: 40px;
        line-height: 34px;
        bottom: 300px;

        &__animated {
          animation: none;
        }

        &__notanimated {
          animation: none;
        }
      }
    }

    &__animated {
      animation: none;
    }

    &__image {
      min-width: 1112px;
      height: 739px;
    }
  }
}

@media (max-width: 650px) {
  .header {
    height: auto;
    &__container {
      max-height: 650px;
      &__harris {
        left: 10px;
      }

      &__trump {
        right: 10px;
      }
    }
  }
}

@media (max-width: 450px) {
  .header {
    &__container {
      &__join {
        width: 90%;
      }
      &__harris {
        &__image {
          left: -10px;
        }
      }

      &__trump {
        &__image {
          right: -20px;
        }
      }
    }
  }
}

@keyframes launchHarris {
  0% {
    bottom: 0;
    left: 60px;
    scale: 1;
  }
  100% {
    bottom: 200px;
    left: 50%;
    transform: translate(-700px) scale(0.5) rotate(96deg);
  }
}

@keyframes launchHarrisBack {
  0% {
    bottom: 200px;
    left: 50%;
    transform: translate(-700px) scale(0.5) rotate(96deg);
  }
  100% {
    bottom: 0;
    left: 60px;
    scale: 1;
  }
}

@keyframes launchTrump {
  0% {
    bottom: 0;
    right: 60px;
    scale: 1;
  }
  100% {
    bottom: 220px;
    right: 50%;
    transform: translate(660px) scale(0.5) rotate(-76deg);
  }
}

@keyframes launchTrumpBack {
  0% {
    bottom: 220px;
    right: 50%;
    transform: translate(660px) scale(0.5) rotate(-76deg);
  }
  100% {
    bottom: 0;
    right: 60px;
    scale: 1;
  }
}

@keyframes launchText {
  0% {
    font-size: 90px;
    bottom: 100px;
  }
  100% {
    font-size: 50px;
    line-height: 45px;
    bottom: 500px;
  }
}

@keyframes launchTextBack {
  0% {
    font-size: 50px;
    line-height: 45px;
    bottom: 500px;
  }
  100% {
    font-size: 90px;
    bottom: 100px;
  }
}

@keyframes launchScroll {
  0% {
    bottom: 20px;
  }
  100% {
    bottom: 450px;
  }
}

@keyframes launchScrollBack {
  0% {
    bottom: 450px;
  }
  100% {
    bottom: 20px;
  }
}

@keyframes launchTimer {
  0% {
    bottom: -500px;
  }
  100% {
    bottom: 80px;
    z-index: 5;
  }
}

@keyframes launchTimerBack {
  0% {
    bottom: 80px;
    z-index: 5;
  }
  100% {
    bottom: -500px;
  }
}
