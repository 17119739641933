:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
  "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
  "Source Han Sans CN", sans-serif;
}

.main-container {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-container,
.main-container * {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  outline: 0;
}

.main-container {
  position: relative;
  width: 100%;
  // height: 3506.559px;
  height: 3076px;
  margin: 0 auto;
}
.apocalypse-travel-guide {
  padding-top: 60px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  //width: 317px;
  height: 96px;
  top: 0;
  color: #ffffff;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 44px;
  font-weight: 600;
  line-height: 48.4px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.76px;
  z-index: 144;
  text-shadow: 4px 4px 0 rgba(255, 0, 0, 0.4);
}
.group {
  margin-top: 40px;
  position: absolute;
  width: 703.5px;
  height: 3487px;
  top: 19.559px;
  left: 569px;
  background: url(../../assets/image/readmap/f13ae802-cfbc-4081-b4a0-0b58a2416c54.png)
  no-repeat center;
  background-size: cover;
  z-index: 1;

  @media (max-width: 1350px) {
    transform: translate(-36%);
    left: 50%;
  }

}
.group-1 {
  position: absolute;
  width: 1000px;
  height: 400px;
  top: 249.559px;
  left: 217px;
  background: url(../../assets/image/readmap/45524ce8-03eb-4e60-88c5-e8602c1d8186.png)
  no-repeat center;
  background-size: cover;
  z-index: 3;
}
.flex-row-cd {
  position: relative;
  width: 906px;
  height: 250px;
  margin: 0 0 0 50px;
  z-index: 11;
}
.image {
  position: absolute;
  width: 450px;
  height: 250px;
  top: 0;
  left: 0;
  background: url(../../assets/image/readmap/5ce1d34dedcfecf5e8ed1f25624b68d1d752914a.png)
  no-repeat center;
  background-size: cover;
  z-index: 4;
}
.phase {
  position: absolute;
  width: 93px;
  height: 22px;
  top: 31px;
  left: 807px;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  z-index: 11;
}
.phase-1 {
  position: relative;
  color: #ffffff;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: left;
  text-transform: uppercase;
}
.text-3 {
  position: relative;
  color: #71dc61;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: left;
  text-transform: uppercase;
}
.social-media-takeover {
  position: absolute;
  width: 186px;
  height: 95px;
  top: 98px;
  left: 497px;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  z-index: 8;
}
.social-media-takeover-2 {
  position: relative;
  color: #71dc61;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.flood-telegram-twitter-x {
  position: relative;
  color: rgba(255, 255, 255, 0.6);
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.air-bomb-campaign {
  position: absolute;
  width: 180px;
  height: 95px;
  top: 98px;
  left: 726px;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  z-index: 9;
}
.air-bomb-campaign-3 {
  position: relative;
  color: #71dc61;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.first-wave-airdrops {
  position: relative;
  color: rgba(255, 255, 255, 0.6);
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.flex-row {
  position: relative;
  width: 919px;
  height: 57px;
  margin: 47px 0 0 50px;
  z-index: 10;
}
.pre-sale-madness {
  position: absolute;
  width: 422px;
  height: 57px;
  top: 0;
  left: 497px;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  z-index: 10;
}
.pre-sale-madness-4 {
  position: relative;
  color: #71dc61;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.text-9 {
  position: relative;
  color: rgba(255, 255, 255, 0.6);
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.pre-apocalypse-prep {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 24px;
  top: 3px;
  left: 0;
  color: #ffffff;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: 6;
}
.q4-2024 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 24px;
  top: 27px;
  left: 0;
  color: #71dc61;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: 7;
}
.backs {
  position: absolute;
  width: 100%;
  height: 3048px;
  top: 285.559px;
  left: 0;
  background: url(../../assets/image/readmap/1e0ec773-c549-4edb-a2c8-2e0d712bc264.png)
  no-repeat center;
  background-size: cover;
}
.rectangle {
  position: absolute;
  width: 900px;
  height: 400px;
  top: 769.559px;
  left: 494px;
  background: url(../../assets/image/readmap/47df024a-e6be-4108-bb29-7a7c00b7649e.png)
  no-repeat center;
  background-size: cover;
  z-index: 52;

  @media (max-width: 1350px) {
    transform: translate(-50%);
    left: 50%;
  }
}
.flex-row-b {
  position: relative;
  width: 250px;
  height: 22px;
  margin: 31px 0 0 0;
  z-index: 87;
}
.phase-5 {
  position: absolute;
  width: 98px;
  height: 22px;
  top: 0;
  left: 49px;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  z-index: 87;
}
.phase-2 {
  position: relative;
  color: #ffffff;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: left;
  text-transform: uppercase;
}
.text-d {
  position: relative;
  color: #ff0000;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: left;
  text-transform: uppercase;
}
.rectangle-6 {
  position: absolute;
  width: 250px;
  top: 19px;
  left: 0;
  background: url(../../assets/image/readmap/8115eef2-3969-4558-abf0-ec260221b1f4.png)
  no-repeat center;
  background-size: cover;
  z-index: 61;
}
.flex-row-b-7 {
  position: relative;
  width: 409px;
  height: 76px;
  margin: 44px 0 0 50px;
  z-index: 85;
}
.tge-launch {
  position: absolute;
  width: 182px;
  height: 76px;
  top: 0;
  left: 0;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  z-index: 84;
}
.tge-launch-8 {
  position: relative;
  color: #ff0101;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.zmbi-goes-live {
  position: relative;
  color: rgba(255, 255, 255, 0.6);
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.dex-listing {
  position: absolute;
  width: 183px;
  height: 57px;
  top: 0;
  left: 226px;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  z-index: 85;
}
.dex-listing-9 {
  position: relative;
  color: #ff0101;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.gamble-end-world {
  position: relative;
  color: rgba(255, 255, 255, 0.6);
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.flex-row-a {
  position: absolute;
  width: 900px;
  height: 400px;
  top: -1px;
  left: -1px;
  z-index: 86;
}
.rectangle-a {
  position: absolute;
  height: 400px;
  top: 0;
  left: 50px;
  background: url(../../assets/image/readmap/3b34596a-96e4-4346-b761-c28b6e31868e.png)
  no-repeat center;
  background-size: cover;
  z-index: 63;
}
.rectangle-b {
  position: absolute;
  height: 400px;
  top: 0;
  left: 100px;
  background: url(../../assets/image/readmap/ac578e65-e2af-4b31-8b80-53e349327d97.png)
  no-repeat center;
  background-size: cover;
  z-index: 64;
}
.rectangle-c {
  position: absolute;
  height: 400px;
  top: 0;
  left: 150px;
  background: url(../../assets/image/readmap/19ae84df-c5aa-46c3-8b78-69191efe6754.png)
  no-repeat center;
  background-size: cover;
  z-index: 65;
}
.rectangle-d {
  position: absolute;
  height: 400px;
  top: 0;
  left: 200px;
  background: url(../../assets/image/readmap/19f5a0a5-3538-455b-9c97-8b522637da16.png)
  no-repeat center;
  background-size: cover;
  z-index: 66;
}
.rectangle-e {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 300px;
  background: url(../../assets/image/readmap/2150cff2-8343-4499-9407-868010abc26a.png)
  no-repeat center;
  background-size: cover;
  z-index: 68;
}
.rectangle-f {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 350px;
  background: url(../../assets/image/readmap/a70c15ee-6388-448c-b25f-89628bee0328.png)
  no-repeat center;
  background-size: cover;
  z-index: 69;
}
.rectangle-10 {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 400px;
  background: url(../../assets/image/readmap/da473da6-e880-4293-b517-ef0e088ed5dc.png)
  no-repeat center;
  background-size: cover;
  z-index: 70;
}
.rectangle-11 {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 450px;
  background: url(../../assets/image/readmap/22fab653-3696-473b-b99d-b74f52ca3f14.png)
  no-repeat center;
  background-size: cover;
  z-index: 71;
}
.rectangle-12 {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 500px;
  background: url(../../assets/image/readmap/0d3fd46c-6a64-499d-8ebc-7e983cdedd39.png)
  no-repeat center;
  background-size: cover;
  z-index: 72;
}
.rectangle-13 {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 550px;
  background: url(../../assets/image/readmap/42022a9d-c3ff-4955-8f04-14b02f774b19.png)
  no-repeat center;
  background-size: cover;
  z-index: 73;
}
.rectangle-14 {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 600px;
  background: url(../../assets/image/readmap/8c9b271d-d485-41df-9e6f-3d0b1d757c27.png)
  no-repeat center;
  background-size: cover;
  z-index: 74;
}
.rectangle-15 {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 650px;
  background: url(../../assets/image/readmap/f8ea762a-5889-4cd7-b200-df5800226bf0.png)
  no-repeat center;
  background-size: cover;
  z-index: 75;
}
.rectangle-16 {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 700px;
  background: url(../../assets/image/readmap/8527ae33-5987-4e48-a5b0-6074ced908e8.png)
  no-repeat center;
  background-size: cover;
  z-index: 76;
}
.rectangle-17 {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 750px;
  background: url(../../assets/image/readmap/32e8321e-ae44-4c77-a4bd-2522e5b2254c.png)
  no-repeat center;
  background-size: cover;
  z-index: 77;
}
.rectangle-18 {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 800px;
  background: url(../../assets/image/readmap/28ed1912-6b4f-40ac-b3c9-d311dd78e612.png)
  no-repeat center;
  background-size: cover;
  z-index: 78;
}
.rectangle-19 {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 850px;
  background: url(../../assets/image/readmap/1344f2c1-a523-45df-8ebe-826b20e5d7fe.png)
  no-repeat center;
  background-size: cover;
  z-index: 79;
}
.rectangle-1a {
  position: absolute;
  width: 0px;
  height: 349px;
  top: 51px;
  left: 250px;
  background: url(../../assets/image/readmap/486adbc7-169e-4475-ac03-047c6616b991.png)
  no-repeat center;
  background-size: cover;
  z-index: 67;
}
.rectangle-1b {
  position: absolute;
  width: 900px;
  top: 100px;
  left: 0;
  background: url(../../assets/image/readmap/89a0b0c9-45f1-4d83-add3-3d129505f839.png)
  no-repeat center;
  background-size: cover;
  z-index: 60;
}
.rectangle-1c {
  position: absolute;
  width: 900px;
  top: 150px;
  left: 0;
  background: url(../../assets/image/readmap/038331d8-7fdd-4846-afaa-7b4054ed2847.png)
  no-repeat center;
  background-size: cover;
  z-index: 59;
}
.rectangle-1d {
  position: absolute;
  width: 900px;
  top: 200px;
  left: 0;
  background: url(../../assets/image/readmap/2f60c6b5-d69b-41d7-9a28-a4faba996bee.png)
  no-repeat center;
  background-size: cover;
  z-index: 58;
}
.rectangle-1e {
  position: absolute;
  width: 900px;
  top: 250px;
  left: 0;
  background: url(../../assets/image/readmap/bbb39acf-a822-4ab1-addb-d81554717472.png)
  no-repeat center;
  background-size: cover;
  z-index: 57;
}
.memes-or-bust {
  position: absolute;
  width: 400px;
  height: 57px;
  top: 297px;
  left: 50px;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  z-index: 86;
}
.memes-or-bust-1f {
  position: relative;
  color: #ff0101;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.kick-off-post-election-apocalypse {
  position: relative;
  color: rgba(255, 255, 255, 0.6);
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.rectangle-20 {
  position: absolute;
  width: 900px;
  top: 300px;
  left: 0;
  background: url(../../assets/image/readmap/2afb1300-4885-4223-afc5-03f39eb4c550.png)
  no-repeat center;
  background-size: cover;
  z-index: 56;
}
.election-night-chaos {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: absolute;
  width: 272px;
  height: 24px;
  top: 300px;
  left: 579px;
  color: #ffffff;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  text-align: right;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: 82;
}
.november {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: absolute;
  width: 220px;
  height: 24px;
  top: 324px;
  left: 631px;
  color: #ff0101;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  text-align: right;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: 83;
}
.rectangle-21 {
  position: absolute;
  width: 900px;
  top: 350px;
  left: 0;
  background: url(../../assets/image/readmap/9e9925fd-29c4-43e4-a85a-befa3c2e307d.png)
  no-repeat center;
  background-size: cover;
  z-index: 55;
}
.image-22 {
  position: absolute;
  width: 450px;
  height: 250px;
  top: 769.559px;
  left: 994px;
  background: url(../../assets/image/readmap/eb55f9bfa777f9ec64331593608cf469a58df062.png)
  no-repeat center;
  background-size: cover;
  z-index: 80;

  @media (max-width: 1350px) {
    left: 700px;
  }
}
.rectangle-23 {
  position: absolute;
  width: 900px;
  height: 400px;
  top: 1289.559px;
  left: 287px;
  background: url(../../assets/image/readmap/f16b1e83-91a6-4501-8823-c55ddc9cbe8b.png)
  no-repeat center;
  background-size: cover;
  z-index: 89;
}
.flex-row-cf {
  position: relative;
  width: 250px;
  height: 22px;
  margin: 31px 0 0 0;
  z-index: 124;
}
.phase-24 {
  position: absolute;
  width: 99px;
  height: 22px;
  top: 0;
  left: 49px;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  z-index: 124;
}
.phase-25 {
  position: relative;
  color: #ffffff;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: left;
  text-transform: uppercase;
}
.text-17 {
  position: relative;
  color: #ff3a8c;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: left;
  text-transform: uppercase;
}
.rectangle-26 {
  position: absolute;
  width: 250px;
  top: 19px;
  left: 0;
  background: url(../../assets/image/readmap/284fa8cd-c860-4f0a-86bf-aa709a93dc0c.png)
  no-repeat center;
  background-size: cover;
  z-index: 98;
}
.nuclear-winter-rally {
  display: block;
  position: relative;
  height: 24px;
  margin: 47px 0 0 450px;
  color: #ffffff;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: 119;
}
.q1-2025 {
  display: block;
  position: relative;
  height: 24px;
  margin: 1px 0 0 450px;
  color: #ff3a8c;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: 120;
}
.geese-energy-initiative {
  position: relative;
  width: 422px;
  height: 38px;
  margin: 170px 0 0 450px;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  z-index: 123;
}
.geese-energy-initiative-27 {
  position: relative;
  color: #ff3a8c;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.begin-beta-testing {
  position: relative;
  color: rgba(255, 255, 255, 0.6);
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.flex-row-efa {
  position: absolute;
  width: 900px;
  height: 400px;
  top: -1px;
  left: -1px;
  z-index: 122;
}
.rectangle-28 {
  position: absolute;
  height: 400px;
  top: 0;
  left: 50px;
  background: url(../../assets/image/readmap/6c71d95e-322a-4fed-b216-908685d59277.png)
  no-repeat center;
  background-size: cover;
  z-index: 100;
}
.rectangle-29 {
  position: absolute;
  height: 400px;
  top: 0;
  left: 100px;
  background: url(../../assets/image/readmap/c7d62937-d8b4-455f-a5ba-99ce2e5ecb9b.png)
  no-repeat center;
  background-size: cover;
  z-index: 101;
}
.rectangle-2a {
  position: absolute;
  height: 400px;
  top: 0;
  left: 150px;
  background: url(../../assets/image/readmap/b08bed18-9c3b-4786-a5d1-f0ac559ecb9b.png)
  no-repeat center;
  background-size: cover;
  z-index: 102;
}
.rectangle-2b {
  position: absolute;
  height: 400px;
  top: 0;
  left: 200px;
  background: url(../../assets/image/readmap/4b586083-b52e-4ecc-9a3d-95147f454d3d.png)
  no-repeat center;
  background-size: cover;
  z-index: 103;
}
.rectangle-2c {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 300px;
  background: url(../../assets/image/readmap/ac2e5d54-afc8-4ac0-9f60-a8846b6b6468.png)
  no-repeat center;
  background-size: cover;
  z-index: 105;
}
.rectangle-2d {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 350px;
  background: url(../../assets/image/readmap/12440652-bc3f-4b8a-8825-e5a954ad8c30.png)
  no-repeat center;
  background-size: cover;
  z-index: 106;
}
.rectangle-2e {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 400px;
  background: url(../../assets/image/readmap/23565a13-8d7f-4369-bcfb-85fbe54dcc74.png)
  no-repeat center;
  background-size: cover;
  z-index: 107;
}
.rectangle-2f {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 450px;
  background: url(../../assets/image/readmap/7e1aa47a-06e9-4660-a182-e57ba81301df.png)
  no-repeat center;
  background-size: cover;
  z-index: 108;
}
.rectangle-30 {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 500px;
  background: url(../../assets/image/readmap/cb72d05c-2614-497e-8e96-822677ddda82.png)
  no-repeat center;
  background-size: cover;
  z-index: 109;
}
.rectangle-31 {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 550px;
  background: url(../../assets/image/readmap/b42b8ae8-6864-40f4-9617-67799bfd94e8.png)
  no-repeat center;
  background-size: cover;
  z-index: 110;
}
.rectangle-32 {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 600px;
  background: url(../../assets/image/readmap/a3c489ce-68a8-4539-a376-366602eed00a.png)
  no-repeat center;
  background-size: cover;
  z-index: 111;
}
.rectangle-33 {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 650px;
  background: url(../../assets/image/readmap/db6a15fe-025d-4b22-a8d1-d112f4482976.png)
  no-repeat center;
  background-size: cover;
  z-index: 112;
}
.rectangle-34 {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 700px;
  background: url(../../assets/image/readmap/dc8bcb73-d0e5-4362-b19e-edb6729f45b0.png)
  no-repeat center;
  background-size: cover;
  z-index: 113;
}
.rectangle-35 {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 750px;
  background: url(../../assets/image/readmap/1bdeab84-151c-4aa3-9ebf-e94f0d0bb10a.png)
  no-repeat center;
  background-size: cover;
  z-index: 114;
}
.rectangle-36 {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 800px;
  background: url(../../assets/image/readmap/d8836820-9aab-48d5-aade-06ace189d804.png)
  no-repeat center;
  background-size: cover;
  z-index: 115;
}
.rectangle-37 {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 850px;
  background: url(../../assets/image/readmap/97d14bd9-386d-4b5e-9cd7-e9177a719db0.png)
  no-repeat center;
  background-size: cover;
  z-index: 116;
}
.rectangle-38 {
  position: absolute;
  width: 0px;
  height: 349px;
  top: 51px;
  left: 250px;
  background: url(../../assets/image/readmap/6c659ca1-c789-4a54-b560-1a8790668eaf.png)
  no-repeat center;
  background-size: cover;
  z-index: 104;
}
.rectangle-39 {
  position: absolute;
  width: 900px;
  top: 100px;
  left: 0;
  background: url(../../assets/image/readmap/3113e12b-614b-497a-9814-066b566fed99.png)
  no-repeat center;
  background-size: cover;
  z-index: 97;
}
.rectangle-3a {
  position: absolute;
  width: 900px;
  top: 150px;
  left: 0;
  background: url(../../assets/image/readmap/c8c33a5c-c424-4374-878d-6b1bf00aeb89.png)
  no-repeat center;
  background-size: cover;
  z-index: 96;
}
.cex-listing {
  position: absolute;
  width: 196px;
  height: 95px;
  top: 181px;
  left: 450px;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  z-index: 121;
}
.cex-listing-3b {
  position: relative;
  color: #ff3a8c;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.expand-to-exchanges {
  position: relative;
  color: rgba(255, 255, 255, 0.6);
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.partnerships {
  position: absolute;
  width: 196px;
  height: 95px;
  top: 181px;
  left: 676px;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  z-index: 122;
}
.partnerships-3c {
  position: relative;
  color: #ff3a8c;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.secure-collabs {
  position: relative;
  color: rgba(255, 255, 255, 0.6);
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.rectangle-3d {
  position: absolute;
  width: 900px;
  top: 200px;
  left: 0;
  background: url(../../assets/image/readmap/c235ab4a-1476-49b7-8c00-7d611cd4ef5a.png)
  no-repeat center;
  background-size: cover;
  z-index: 95;
}
.rectangle-3e {
  position: absolute;
  width: 900px;
  top: 250px;
  left: 0;
  background: url(../../assets/image/readmap/ea4d1eb7-d21c-4279-99d8-7ba352181d52.png)
  no-repeat center;
  background-size: cover;
  z-index: 94;
}
.rectangle-3f {
  position: absolute;
  width: 900px;
  top: 300px;
  left: 0;
  background: url(../../assets/image/readmap/cc891e90-0b23-455b-9359-236671bef051.png)
  no-repeat center;
  background-size: cover;
  z-index: 93;
}
.rectangle-40 {
  position: absolute;
  width: 900px;
  top: 350px;
  left: 0;
  background: url(../../assets/image/readmap/16a47f43-4ca4-4b13-8ee2-bb444e025271.png)
  no-repeat center;
  background-size: cover;
  z-index: 92;
}
.image-41 {
  position: absolute;
  width: 450px;
  height: 250px;
  top: 1389.559px;
  left: 237px;
  background: url(../../assets/image/readmap/eb1e0b4ac3ee21a68b344ba895d607bb82658b46.png)
  no-repeat center;
  background-size: cover;
  z-index: 117;
}
.group-42 {
  position: absolute;
  width: 700px;
  height: 500px;
  top: 1809.559px;
  left: 639px;
  background: url(../../assets/image/readmap/73f07392-9cfd-4cab-9bad-21d90b12ee15.png)
  no-repeat center;
  background-size: cover;
  z-index: 126;

  @media (max-width: 1350px) {
    transform: translate(-50%);
    left: 60%;
  }
}
.flex-column-c {
  position: absolute;
  width: 201px;
  height: 414px;
  top: 31px;
  left: 450px;
  z-index: 134;
}
.phase-43 {
  position: relative;
  width: 98px;
  height: 22px;
  margin: 0 0 0 102px;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  z-index: 134;
}
.phase-44 {
  position: relative;
  color: #ffffff;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: left;
  text-transform: uppercase;
}
.empty {
  position: relative;
  color: #ffffff;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
  text-transform: uppercase;
}
.number-4 {
  position: relative;
  color: #ffdc6b;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
  text-transform: uppercase;
}
.bunker-access-utility {
  position: relative;
  width: 200px;
  height: 76px;
  margin: 46px 0 0 1px;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  z-index: 131;
}
.bunker-access-utility-45 {
  position: relative;
  color: #ffdc6b;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.token-holders-access {
  position: relative;
  color: rgba(255, 255, 255, 0.6);
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.nuclear-metaverse {
  position: relative;
  width: 199px;
  height: 95px;
  margin: 40px 0 0 1px;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  z-index: 132;
}
.nuclear-metaverse-46 {
  position: relative;
  color: #ffdc6b;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.deploy-virtual-experiences {
  position: relative;
  color: rgba(255, 255, 255, 0.6);
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.merch-drop {
  position: relative;
  width: 201px;
  height: 95px;
  margin: 40px 0 0 0;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  z-index: 133;
}
.merch-drop-47 {
  position: relative;
  color: #ffdc6b;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.launch-clothing-line {
  position: relative;
  color: rgba(255, 255, 255, 0.6);
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.flex-column {
  position: absolute;
  width: 282px;
  height: 48px;
  top: 299px;
  left: 50px;
  font-size: 0px;
  z-index: 130;
}
.wasteland-dominance {
  display: block;
  position: relative;
  height: 24px;
  margin: 0 0 0 0;
  color: #ffffff;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: 129;
}
.q2-2025 {
  display: block;
  position: relative;
  height: 24px;
  margin: 0 0 0 0;
  color: #ffdc6b;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: 130;
}
.image-48 {
  position: absolute;
  width: 450px;
  height: 250px;
  top: 1809.559px;
  left: 589px;
  background: url(../../assets/image/readmap/abc713ad63cbd8476f7854993706400a3500adba.png)
  no-repeat center;
  background-size: cover;
  z-index: 127;

  @media (max-width: 1350px) {
    left: 366px;
  }
}
.rectangle-49 {
  position: absolute;
  width: 900px;
  height: 400px;
  top: 2429.559px;
  left: 253px;
  background: url(../../assets/image/readmap/c6885a19-20dc-4d84-9623-2172c55a88d0.png)
  no-repeat center;
  background-size: cover;
  z-index: 14;
}
.flex-row-4a {
  position: relative;
  width: 250px;
  height: 22px;
  margin: 31px 0 0 0;
  z-index: 49;
}
.phase-4b {
  position: absolute;
  width: 99px;
  height: 22px;
  top: 0;
  left: 49px;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  z-index: 49;
}
.phase-4c {
  position: relative;
  color: #ffffff;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: left;
  text-transform: uppercase;
}
.number-5 {
  position: relative;
  color: #65abff;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: left;
  text-transform: uppercase;
}
.rectangle-4d {
  position: absolute;
  width: 250px;
  top: 19px;
  left: 0;
  background: url(../../assets/image/readmap/44ac56c8-c7ec-4d54-b09c-e680e78f8d9b.png)
  no-repeat center;
  background-size: cover;
  z-index: 23;
}
.flex-row-ccf {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 409px;
  height: 95px;
  margin: 44px 0 0 50px;
  z-index: 47;
}
.social-media-takeover-4e {
  flex-shrink: 0;
  position: relative;
  width: 182px;
  height: 95px;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  z-index: 46;
}
.social-media-takeover-4f {
  position: relative;
  color: #65abff;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.flood-social-media {
  position: relative;
  color: rgba(255, 255, 255, 0.6);
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.air-bomb-campaign-50 {
  flex-shrink: 0;
  position: relative;
  width: 183px;
  height: 95px;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  z-index: 47;
}
.air-bomb-campaign-51 {
  position: relative;
  color: #65abff;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.first-wave-airdrops-52 {
  position: relative;
  color: rgba(255, 255, 255, 0.6);
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.flex-row-dc {
  position: absolute;
  width: 900px;
  height: 400px;
  top: -1px;
  left: -1px;
  z-index: 48;
}
.rectangle-53 {
  position: absolute;
  height: 400px;
  top: 0;
  left: 50px;
  background: url(../../assets/image/readmap/2ba60a38-ecb4-4520-bdaa-40af65613ca1.png)
  no-repeat center;
  background-size: cover;
  z-index: 25;
}
.rectangle-54 {
  position: absolute;
  height: 400px;
  top: 0;
  left: 100px;
  background: url(../../assets/image/readmap/471b6277-14cc-4570-a0e4-d13f7f0b1965.png)
  no-repeat center;
  background-size: cover;
  z-index: 26;
}
.rectangle-55 {
  position: absolute;
  height: 400px;
  top: 0;
  left: 150px;
  background: url(../../assets/image/readmap/3de4f9de-c63d-4002-af16-5582a249a585.png)
  no-repeat center;
  background-size: cover;
  z-index: 27;
}
.rectangle-56 {
  position: absolute;
  height: 400px;
  top: 0;
  left: 200px;
  background: url(../../assets/image/readmap/e6153884-1714-4cd1-88bd-b5903a389e14.png)
  no-repeat center;
  background-size: cover;
  z-index: 28;
}
.rectangle-57 {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 300px;
  background: url(../../assets/image/readmap/f338ff80-8d12-4399-a062-8e2a715ac90f.png)
  no-repeat center;
  background-size: cover;
  z-index: 30;
}
.rectangle-58 {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 350px;
  background: url(../../assets/image/readmap/bb225725-2145-425a-a8a6-1d2f1def4d59.png)
  no-repeat center;
  background-size: cover;
  z-index: 31;
}
.rectangle-59 {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 400px;
  background: url(../../assets/image/readmap/40c4a699-74ce-4a16-a3b3-f27f0e6c02bb.png)
  no-repeat center;
  background-size: cover;
  z-index: 32;
}
.rectangle-5a {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 450px;
  background: url(../../assets/image/readmap/216df6f2-9272-4a3e-8e0c-fb80738a1834.png)
  no-repeat center;
  background-size: cover;
  z-index: 33;
}
.rectangle-5b {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 500px;
  background: url(../../assets/image/readmap/4319567f-9a11-41a2-aff0-7f6bc10842a2.png)
  no-repeat center;
  background-size: cover;
  z-index: 34;
}
.rectangle-5c {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 550px;
  background: url(../../assets/image/readmap/3124adf5-9831-43ad-9f6f-14c98085a98b.png)
  no-repeat center;
  background-size: cover;
  z-index: 35;
}
.rectangle-5d {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 600px;
  background: url(../../assets/image/readmap/4eaa2c66-88a1-41f1-bb6b-812e1353e417.png)
  no-repeat center;
  background-size: cover;
  z-index: 36;
}
.rectangle-5e {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 650px;
  background: url(../../assets/image/readmap/a069a830-cf7f-4cbf-9ec5-5d2d3ace85cc.png)
  no-repeat center;
  background-size: cover;
  z-index: 37;
}
.rectangle-5f {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 700px;
  background: url(../../assets/image/readmap/8c20e4f5-988f-489a-ad87-b47843eb293b.png)
  no-repeat center;
  background-size: cover;
  z-index: 38;
}
.rectangle-60 {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 750px;
  background: url(../../assets/image/readmap/e7a67fd2-4827-4de2-99fb-44033c172ec9.png)
  no-repeat center;
  background-size: cover;
  z-index: 39;
}
.rectangle-61 {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 800px;
  background: url(../../assets/image/readmap/e89b2a9f-9048-48e1-9cba-d1147c50d258.png)
  no-repeat center;
  background-size: cover;
  z-index: 40;
}
.rectangle-62 {
  position: absolute;
  height: 350px;
  top: 50px;
  left: 850px;
  background: url(../../assets/image/readmap/2c13c104-2bf6-407e-acab-5a88dec2bed9.png)
  no-repeat center;
  background-size: cover;
  z-index: 41;
}
.rectangle-63 {
  position: absolute;
  width: 0px;
  height: 349px;
  top: 51px;
  left: 250px;
  background: url(../../assets/image/readmap/9982041f-b0f6-48ec-8db6-c280022d9138.png)
  no-repeat center;
  background-size: cover;
  z-index: 29;
}
.rectangle-64 {
  position: absolute;
  width: 900px;
  top: 100px;
  left: 0;
  background: url(../../assets/image/readmap/ddb821ff-fab8-4238-b6a0-9b51be047c96.png)
  no-repeat center;
  background-size: cover;
  z-index: 22;
}
.rectangle-65 {
  position: absolute;
  width: 900px;
  top: 150px;
  left: 0;
  background: url(../../assets/image/readmap/d10e4047-18b3-41d9-90c3-febb60e121d5.png)
  no-repeat center;
  background-size: cover;
  z-index: 21;
}
.rectangle-66 {
  position: absolute;
  width: 900px;
  top: 200px;
  left: 0;
  background: url(../../assets/image/readmap/c3a9c9a8-cbbb-4031-aedd-3e796cea83e6.png)
  no-repeat center;
  background-size: cover;
  z-index: 20;
}
.rectangle-67 {
  position: absolute;
  width: 900px;
  top: 250px;
  left: 0;
  background: url(../../assets/image/readmap/0a1ba030-ec32-4320-9123-a8edc174dec7.png)
  no-repeat center;
  background-size: cover;
  z-index: 19;
}
.pre-sale-madness-68 {
  position: absolute;
  width: 400px;
  height: 57px;
  top: 297px;
  left: 50px;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  z-index: 48;
}
.pre-sale-madness-69 {
  position: relative;
  color: #65abff;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.pre-sale-madness-6a {
  position: relative;
  color: rgba(255, 255, 255, 0.6);
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.rectangle-6b {
  position: absolute;
  width: 900px;
  top: 300px;
  left: 0;
  background: url(../../assets/image/readmap/9a7fe234-f64b-437f-99d8-1bf25ffe4d69.png)
  no-repeat center;
  background-size: cover;
  z-index: 18;
}
.pre-apocalypse-prep-6c {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 24px;
  top: 300px;
  left: 580px;
  color: #ffffff;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: 44;
}
.pre-apocalypse-prep-6d {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 24px;
  top: 324px;
  left: 729px;
  color: #65abff;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: 45;
}
.rectangle-6e {
  position: absolute;
  width: 900px;
  top: 350px;
  left: 0;
  background: url(../../assets/image/readmap/59f51dbf-0a74-42fc-a409-592f5f6edda1.png)
  no-repeat center;
  background-size: cover;
  z-index: 17;
}
.image-6f {
  position: absolute;
  width: 450px;
  height: 250px;
  top: 2429.559px;
  left: 753px;
  background: url(../../assets/image/readmap/2d83da5211e5af9913fa3e2e637214ba56ebc5b0.png)
  no-repeat center;
  background-size: cover;
  z-index: 42;
}
.group-70 {
  position: absolute;
  width: 750px;
  height: 450px;
  // top: 2949.559px;
  top: 2429.559px;
  left: 453px;
  background: url(../../assets/image/readmap/b59fee67-ff4e-46a5-a4b6-74908daf42a7.png)
  no-repeat center;
  background-size: cover;
  z-index: 136;
}
.flex-column-ae {
  position: absolute;
  width: 249px;
  height: 347px;
  top: 31px;
  left: 451px;
  z-index: 143;
}
.phase-final {
  position: relative;
  width: 150px;
  height: 22px;
  margin: 0 0 0 99px;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: right;
  text-overflow: initial;
  white-space: nowrap;
  z-index: 143;
}
.phase-71 {
  position: relative;
  color: #ffffff;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: right;
  text-transform: uppercase;
}
.phase-72 {
  position: relative;
  color: #ffffff;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: right;
  text-transform: uppercase;
}
.final {
  position: relative;
  color: #6bffdf;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: right;
  text-transform: uppercase;
}
.goosechain-mainnet-launch {
  position: relative;
  width: 249px;
  height: 95px;
  margin: 96px 0 0 0;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  z-index: 141;
}
.goosechain-mainnet-launch-73 {
  position: relative;
  color: #6bffdf;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.goosechain-mainnet-launch-74 {
  position: relative;
  color: rgba(255, 255, 255, 0.6);
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.post-apocalypse-adoption {
  position: relative;
  width: 241px;
  height: 95px;
  margin: 39px 0 0 0;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  z-index: 142;
}
.post-apocalypse-adoption-75 {
  position: relative;
  color: #6bffdf;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.post-apocalypse-adoption-76 {
  position: relative;
  color: rgba(255, 255, 255, 0.6);
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
}
.flex-column-e {
  position: absolute;
  width: 299px;
  height: 72px;
  top: 299px;
  left: 50px;
  font-size: 0px;
  z-index: 140;
}
.endgame-life-after-the-boom {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 253px;
  height: 48px;
  margin: 0 0 0 0;
  color: #ffffff;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  text-overflow: initial;
  text-transform: uppercase;
  z-index: 139;
  overflow: hidden;
}
.endgame-life-after-the-boom-77 {
  display: block;
  position: relative;
  height: 24px;
  margin: 0 0 0 0;
  color: #6bffdf;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: 140;
}
.image-78 {
  position: absolute;
  width: 450px;
  height: 250px;
  // top: 2949.559px;
  top: 2429.559px;
  left: 403px;
  background: url(../../assets/image/readmap/2862b5bdb136826513144c6fd4c3688c85522c5b.png)
  no-repeat center;
  background-size: cover;
  z-index: 137;
}

.btnTimer {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  width: 471px;
  height: 69px;
  background-color: #ff0202;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  border: none;
  z-index: 999;
  padding: 20px 17px;

  @media (max-width: 1024px) {
    width: 330px;
    height: 50px;
    color: var(--Neutral-100, #FFF);
    text-align: center;
    font-size: 16px;
    line-height: 120%;
    text-transform: uppercase;
  }

  a {
    color: #ffffff;
    text-decoration: none;
  }
}
