.sea {
  background-image: url('../../assets/image/sea.jpg');
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;

  &__submarine {
    position: absolute;
    bottom: -20px;
    right: 0;
    z-index: 5;
  }

  &__container {
    width: 100%;
    height: 782px;
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
  }

  &__small {
    display: flex;
    flex-direction: column;
    width: 300px;
    align-items: center;
    margin: 0 auto;
    margin-top: 40px;

    &__description {
      color: #ffcece;
      text-shadow: 0 4px 44px #ff0202;
      font-size: 30px;
      text-align: center;
    }

    &__button {
      background-color: #ff0202;
      border: none;
      width: 300px;
      height: 40px;
      font-size: 16px;
      text-transform: uppercase;
      margin-top: 15px;
      z-index: 999;

      @media (max-width: 1100px) {
        display: none;
      }

      a {
        color: #ffffff;
        text-decoration: none;
      }
    }

    img {
      width: 90px;
      height: 90px;
    }
  }

  &__missile {
    position: absolute;
    bottom: 186px;
    right: 369px;
    transform: rotate(15deg);

    &__animated {
      animation: launchRocket 2s ease-in-out forwards;
    }
  }

  &__explosion {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 782px;
    scale: 1.05;
  }

  &__shadow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
  }

  &__text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999;

    &__scale {
      animation: launchText 4s ease-in-out forwards;
    }

    &__description {
      width: 911px;
      font-size: 90px;
      font-weight: 500;
      color: #ffcece;
      text-align: center;
      text-transform: uppercase;
      text-shadow: 0 4px 44px #ff0202;
      line-height: 81px;
    }

    &__button {
      width: 471px;
      height: 69px;
      border: none;
      background-color: #ff0202;
      color: #ffffff;
      text-transform: uppercase;
      margin-top: 46px;
      font-size: 24px;
      font-weight: 700;
      z-index: 999;

      a {
        color: #ffffff;
        text-decoration: none;
      }
    }
  }

  &__neptun {
    position: absolute;
    bottom: -671px;
    left: 67px;
    z-index: 5;

    &__animated {
      animation: launchNeptun 4s ease-in-out forwards;

      &__hide {
        animation: launchHide 4s ease-in-out forwards;
      }
    }
  }
}

@keyframes launchRocket {
  0% {
    bottom: 186px;
    right: 369px;
    transform: scale(1) rotate(15deg);
    opacity: 1;
  }
  65% {
    bottom: 300px;
    right: 400px;
    transform: scale(0.3);
    opacity: 0.8;
  }
  66% {
    transform: scale(0.3) rotate(-110deg);
  }
  100% {
    bottom: 50px;
    right: 500px;
    transform: scale(0.2) rotate(-110deg);
    opacity: 0.6;
  }
}

@keyframes launchNeptun {
  0% {
    bottom: -671px;
    left: 67px;
  }
  100% {
    bottom: 0;
    left: 67px;
  }
}

@keyframes launchText {
  0% {
    scale: 1;
  }
  100% {
    scale: 0.9;
  }
}

@keyframes launchHide {
  0% {
    bottom: 0;
    left: 67px;
  }
  100% {
    bottom: -671px;
    left: 67px;
  }
}

@media (max-width: 1500px) {
  .sea {
    &__submarine {
      right: -80px;
    }
  }
}

@media (max-width: 1300px) {
  .sea {
    &__submarine {
      scale: 0.8;
    }

    &__missile {
      scale: 0.8;
    }

    &__neptun {
      scale: 0.8;
      bottom: -545px;
    }
  }
}

@media (max-width: 1100px) {
  .sea {
    margin-top: -1px;
    background-size: cover;

    &__submarine {
      scale: 0.4;
      right: -320px;
      bottom: -100px;
    }

    &__missile {
      scale: 0.5;
      right: 0;
      bottom: 0;

      &__animated {
        animation: none;
      }
    }

    &__text {
      &__scale {
        animation: none;
      }
    }

    &__neptun {
      &__animated {
        animation: none;

        &__hide {
          animation: none;
        }
      }
    }
  }
}
