@import '../../styles/pixel-size.scss';

.footer {
  background-color: #000;
  width: 100%;
  //min-height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 46px 0;

  &__shadow {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 3;
  }

  &__ellipse {
    //position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
  }
}

.container {
  margin: 0 auto;
  max-width: 1242px;
  padding: 0 16px;
}

.timer {
  $clock-size: 825px;

  position: relative;
  width: 100%;
  height: min($clock-size, 100vw);
  background-image:
    url(../../assets/image/ellipse.svg),
    url(../../assets/image/flash-footer.svg),
    url(../../assets/image/red-bg.svg),
    url(../../assets/image/journey.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: -232px;

  &:before {
    content: '';
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, black 100%);
    background-position: center bottom;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 1;
  }

  &__clock {
    position: absolute;
    height: auto;
    width: $clock-size;
    max-width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }

  &__text {
    text-align: center;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: -50px;
  }

  &__zmbi {
    font-size: 70px;
    font-weight: 600;
    color: #ffffff;
    text-shadow: 0 4px 44px #ff0202;
  }

  &__rocketing {
    color: #ff0202;
    font-size: 32px;
    font-weight: 600;
    text-transform: uppercase;
  }

  &__date {
    color: #ffffff;
    font-size: 32px;
    font-weight: 600;
    text-transform: uppercase;
  }

  &__icon {
    width: 150px;
    height: 150px;
    margin-bottom: -40px;
  }

  @media (max-width: $clock-size) {
    margin-bottom: pixel-size(-232px, $clock-size);

    &__text {
      margin-top: pixel-size(-50px, $clock-size);
    }

    &__clock {
      width: pixel-size($clock-size, $clock-size);
    }

    &__zmbi {
      font-size: pixel-size(70px, $clock-size);
    }

    &__rocketing {
      font-size: pixel-size(32px, $clock-size);
    }

    &__date {
      font-size: pixel-size(32px, $clock-size);
    }

    &__icon {
      width: pixel-size(150px, $clock-size);
      height: pixel-size(150px, $clock-size);
      margin-bottom: pixel-size(-40px, $clock-size);
    }
  }
}

.nav {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  gap: 35px;
  position: relative;
  z-index: 1;
  margin-bottom: 48px;

  &__item {
    color: #ffffff;
    cursor: pointer;
    font-weight: 500;
    text-decoration: none;
  }

  &__button {
    margin-left: 40px;
    padding: 8px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 1px solid #ff0202;
    font-weight: 700;
    cursor: pointer;
    color: #ffffff;
    text-decoration: none;
  }
}

.warning {
  text-align: center;
  font-size: 14px;
  color: #717171;
  position: relative;
  z-index: 1;
  margin-top: auto;
  line-height: 1.4;

  &__title {
    display: flex;
    justify-content: center;
    color: #FF0202;
    font-weight: 600;
    margin-bottom: 19px;
  }

  &__content {
    p {
      margin: 37px 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__icon {
    margin-right: 12px;
  }
}

@media (max-width: 1100px) {
  .nav {
    flex-direction: row;
    gap: 20px;
  }
}

$mobile-size: 378px;

@media (max-width: 638px) {
  .footer {
    padding: 30px 0;
  }

  .timer {
    background: none;
    margin-top: pixel-size(-40px, $mobile-size);
    margin-bottom: pixel-size(-45px, $mobile-size);
    overflow: hidden;

    &__clock {
      max-width: none;
      width: pixel-size(430px, $mobile-size);
    }

    &__zmbi {
      font-size: pixel-size(40px, $mobile-size);
    }

    &__rocketing {
      font-size: pixel-size(20px, $mobile-size);
    }

    &__date {
      font-size: pixel-size(20px, $mobile-size);
    }

    &__icon {
      width: pixel-size(100px, $mobile-size);
      height: pixel-size(100px, $mobile-size);
      margin-bottom: pixel-size(-30px, $mobile-size);
    }

    &__text {
      margin-top: pixel-size(-32px, $mobile-size);
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(transparent 0%, black 75%, black 100%);
      display: block;
      content: '';
      z-index: 1;
    }
  }

  .nav {
    flex-direction: column;
    order: -1;

    &__button {
      margin: 10px 0 0;
      padding: 1px 20px;
    }
  }

  .warning {
    &__content {
      p {
        margin: 50px 0;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
