.wrapper {
  max-width: 1920px;
  margin: 0 auto;
}
.scroll-container {
  height: 100vh;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  scroll-snap-stop: always;
}

.header {
  //height: 1000px;
}

.section {
  //height: 100vh;
}

.content {
  scroll-snap-align: start;
}

.fp-watermark {
  display: none;
}

.fp-overflow {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &/* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
}
