.slider {
  width: 100%;
  min-height: 100vh;
  padding: 30px 0 68px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  overflow: hidden;

  &__descWrapper {
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }

  &__descContainer {
    color: rgba(255, 255, 255, 0.60);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  &__header {
    color: var(--Neutral-100, #FFF);
    text-align: center;
    text-shadow: 4px 4px 0 rgba(255, 0, 0, 0.40);
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 30.8px */
    letter-spacing: 1.12px;
    text-transform: uppercase;
    padding: 0 40px;
  }

  &__position {
    padding-top: 15px;
    padding-right: 15px;
    position: absolute;
    color: var(--Neutral-100, #FFF);
    text-align: right;
    width: 100%;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 90%; /* 14.4px */
    text-transform: uppercase;
  }

  &__card {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-image: url('../../assets/image/slider/slider-bg.svg');
    padding-bottom: 30px;
    position: relative;

    &__image {
      flex: 0 0 auto;
      padding: 46px 15px 0 15px;
    }

    &__bottom {
      padding: 34px 16px 30px 16px;
      font-size: 20px;
      font-weight: 600;
      line-height: 22px;
      text-transform: uppercase;

      &__description {
        color: #fff;
      }
    }
  }

  &__slider {
    width: 100%;
    display: flex;
    transition: transform 0.5s ease-in-out;
    flex: 0 0 auto;
    align-self: stretch;
    padding-left: 30px;
    gap: 45px;
  }

  &__arrows {
    width: 100%;
    padding: 0 15px;
    flex: 0 0 auto;
    margin-bottom: 36px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;

      &:disabled {
        img {
          filter: grayscale(100%) brightness(0.5);
        }
      }
    }

    &__prev {
      transform: rotate(180deg);
    }
  }

  &__pagination {
    display: none;
  }
}

@media (max-width: 600px) {
  .slider {

    &__pagination {
      display: block;

      &__list {
        display: flex;
        align-items: flex-end;
        list-style: none;
        padding: 0 16px 16px 16px;
        margin: 0;
        height: 50px;
        gap: 20px;
      }

      &__item {
        width: 8px;
        height: 8px;
        background: white;
        margin: 0 4px;
        transition: 0.5s ease-in-out;
        border-radius: 100%;
        fill: var(--Neutral-100, #FFF);
        opacity: 0.2;

        &__active {
          background: #FF0202;
          opacity: 1;
        }
      }
    }
  }
}

.btnTimer {
  margin-top: 20px;
  width: 471px;
  height: 69px;
  background-color: #ff0202;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  border: none;
  z-index: 9999;

  @media (max-width: 1024px) {
    width: 330px;
    height: 50px;
    color: var(--Neutral-100, #FFF);
    text-align: center;
    font-size: 16px;
    line-height: 120%;
    text-transform: uppercase;
  }

  a {
    color: #ffffff;
    text-decoration: none;
  }
}
