:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
  "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
  "Source Han Sans CN", sans-serif;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  min-width: 0;
  width: 719px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  @media (max-width: 1024px) {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__openBtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    flex-shrink: 0;
    gap: 29px;
    width: 100%;
    height: 70px;
    padding: 20px 0 20px 0;
    font-family: Chakra Petch, var(--default-font-family);
    font-size: 24px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
    text-overflow: initial;
    color: #ffffff;

    @media (max-width: 1024px) {
      font-size: 16px;
      font-weight: 500;
      line-height: 110%;
    }

    & img {
      width: 30px;
      height: 30px;
    }
  }

  &__content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    width: 100%;
    padding: 0 0 24px 0;
    color: #ffffff;
    font-family: Chakra Petch, var(--default-font-family);
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
    text-align: left;

    @media (max-width: 1024px) {
      font-size: 14px;
      font-weight: 400;
      line-height: 140%;
      padding: 0 0 16px 0;
    }
  }
}
