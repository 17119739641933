:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
  "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
  "Source Han Sans CN", sans-serif;
}

.main-container,
.main-container * {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  outline: 0;
}

.main-container {
  position: relative;
  //height: 1426px;
  font-size: 0px;
  padding-top: 92px;
  display: flex;
  justify-content: end;
  flex-direction: column;
  align-items: end;
  text-align: center;

  @media (max-width: 1024px) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 32px;
  }
}

.containerTitle {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.frequently-asked-questions {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  height: 92px;
  color: #ffffff;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 42px;
  font-weight: 600;
  line-height: 46.2px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.68px;
  text-shadow: 4px 4px 0 rgba(255, 0, 0, 0.4);

  @media (max-width: 1024px) {
    font-size: 28px;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: 1.12px;
  }
}

.flex-row-b {
  position: relative;
  //width: 1315px;
  z-index: 56;
  margin: 0 auto;
  margin-top: 70px;

  @media (max-width: 1150px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 70px;
  }

  @media (max-width: 1440px) {
    //width: 1115px;
  }

  @media (max-width: 1024px) {
    margin-top: 30px;
    padding-left: 18px;
    padding-right: 18px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  position: absolute;
  width: 719px;
  height: 1264px;
  top: 0;
  left: 50%;
  transform: translate(-77.54%, 0);
  z-index: 1;
}
.frame-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  min-width: 0;
  padding: 0 0 24px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  z-index: 2;
}
.frame-2 {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 29px;
  position: relative;
  width: 719px;
  height: 70px;
  padding: 20px 0 20px 0;
  z-index: 3;
}

.what-chain-zmbi {
  flex-shrink: 0;
  position: relative;
  width: 660px;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  color: #ffffff;
  z-index: 4;
}

.frame-3 {
  flex-shrink: 0;
  position: relative;
  width: 30px;
  height: 30px;
  z-index: 5;
  overflow: hidden;
}

.vector {
  position: relative;
  width: 22.627px;
  height: 22.627px;
  margin: 3.293px 0 0 3.293px;
  background: url(../../assets/image/faq/open.png)
  no-repeat center;
  background-size: cover;
  z-index: 6;
}

.zmbi-launch-solana {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  width: 660px;
  height: 50px;
  color: #ffffff;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: left;
  z-index: 7;
}

.frame-2e {
  position: absolute;
  width: 209px;
  height: 480px;
  top: 200px;
  right: 0;
  z-index: 56;
  overflow: hidden;

  @media (max-width: 1130px) {
    display: none;
  }
}

.question-mark-2f {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 143px;
  top: 0;
  left: 51px;
  color: rgba(255, 1, 1, 0.1);
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 130px;
  font-weight: 700;
  line-height: 143px;
  text-align: left;
  white-space: nowrap;
  opacity: 0.5;
  z-index: 58;
  -webkit-text-stroke: 2px #ff0101;
}

.image {
  position: absolute;
  width: 243.535px;
  height: 407.963px;
  top: 72.233px;
  left: 23.768px;
  background: url(../../assets/image/faq/zombie.png)
  no-repeat center;
  background-size: cover;
  z-index: 57;
}

.question-mark-30 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 99px;
  top: 102px;
  left: 0;
  color: rgba(255, 1, 1, 0.1);
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 90px;
  font-weight: 700;
  line-height: 99px;
  text-align: left;
  white-space: nowrap;
  opacity: 0.3;
  z-index: 59;
  -webkit-text-stroke: 2px #ff0101;
}

.what-chain-zmbi {
  flex-shrink: 0;
  position: relative;
  width: 660px;
  font-family: Chakra Petch, var(--default-font-family);
  font-size: 24px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  z-index: 4;
}
