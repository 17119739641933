.section {
  background-color: #000000;
  background-image:
    radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.90) 98.34%, #000 100%),
    url('../../assets/image/test-bg.jpg');
  background-size: max(100vw, 100vh), max(120vw, 180vh);
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-y: scroll;
  transition: height 0.3s ease;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &_light {
    background-image:
      radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.36) 0%, rgba(0, 0, 0, 0.54) 98.34%, rgba(0, 0, 0, 0.60) 100%),
      url('../../assets/image/test-bg.jpg');
  }

  &__nav {
    flex: 0 0 auto;
  }

  &/* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }


  &__container {
    flex: 1 1 auto;
    padding-top: 102px;
    padding-bottom: 102px;

    @media (max-width: 1100px) {
      padding-top: 66px;
    }
  }

  &__mainTitleContainer {
    margin-bottom: 91px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0 200px;

    @media (max-width: 1100px) {
      margin-top: 120px;
      padding: 0 40px;
      margin-bottom: 66px;
    }

    @media (max-width: 600px) {
      margin-top: 88px;
      margin-bottom: 52px;
    }
  }

  &__mainContent {
    background-image: url('../../assets/image/bg-test.png');
    background-position: 100% 100%;
    max-width: 1320px;
    filter: blur(0px);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 48px 80px;
    margin: 0 80px;
    gap: 40px;

    @media (max-width: 1100px) {
      margin: 0 30px;
      flex-direction: column;
    }

    @media (max-width: 600px) {
      flex-direction: column;
      padding: 20px 0;
    }

    img,
    .placeholder {
      width: 200px;
      height: 200px;
      flex-shrink: 0;

      @media (max-width: 1100px) {
        width: 230px;
        height: 230px;
        margin-left: 10px;
      }

      @media (max-width: 600px) {
        width: 300px;
        height: 300px;
        flex-shrink: 0;
        margin-left: 0;
      }
    }

    .placeholder {
      padding: 32px;
      display: flex;

      &:before {
        border-radius: 16px;
        display: block;
        content: '';
        background: rgba(white, 0.1);
        width: 100%;
        height: 100%;
      }
    }
  }

  &__mainContentTextContainer {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-right: 87px;
    opacity: 0;

    @media (max-width: 1100px) {
      margin-right: 52px;
      gap: 22px;
      display: none;
    }

    @media (max-width: 600px) {
      margin-right: 0;
      gap: 0;
      padding-bottom: 16px;
    }
  }

  &__balance {
    background-image: url('../../assets/image/check-nft-balance.svg');
    width: 445px;
    height: 212px;
    padding: 16px 24px 0 24px;

    @media (max-width: 1100px) {}

    @media (max-width: 600px) {
      background-image: none;
      width: 100%;
      height: 100%;
    }

    &__title {
      color: var(--Neutral-100, #FFF);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      text-transform: uppercase;

      @media (max-width: 600px) {
        font-size: 22px;
      }
    }

    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 78px;

      & svg {
        @media (max-width: 600px) {
          width: 14px;
          height: 14px;
        }
      }

      @media (max-width: 600px) {
        margin-top: 15px;
        justify-content: start;
        gap: 12px;
      }
    }

    &__nftToken {
      color: var(--Neutral-100, #FFF);
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      text-transform: uppercase;

      @media (max-width: 600px) {
        font-size: 16px;
      }
    }

    &__token {

    }
  }

  &__wrapperLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 68px;
    width: 100%;
    height: 100%;

    @media (max-width: 600px) {
      gap: 20px;
    }

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      color: var(--Neutral-100, #FFF);
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 48px */
      text-transform: uppercase;

      @media (max-width: 600px) {
        font-size: 24px;
      }

      & p {
        color: #FF0202;
        font-size: 28px;
        margin: 0;

        @media (max-width: 600px) {
          font-size: 18px;
        }
      }
    }
  }

  &__containerButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    width: 300px;

    &__withdrawal {
      height: 55px;
      width: 100%;
      display: flex;
      padding: 20px 30px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      background: #FF0202;
      box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 0.25);
      text-transform: uppercase;
      cursor: pointer;
      transition: 200ms;
      border: none;
      flex: 0 0 auto;
      color: var(--Neutral-100, #FFF);
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
    }

    &__claim {
      height: 55px;
      width: 100%;
      display: flex;
      padding: 20px 30px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border: 1px solid #F00;
      box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 0.25);
      text-transform: uppercase;
      cursor: pointer;
      transition: 200ms;
      flex: 0 0 auto;
      color: var(--Neutral-100, #FFF);
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      background: transparent;
    }
  }

  &__wrapperBalance {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;

    @media (max-width: 1100px) {
      flex-direction: column;
      align-items: center;
    }

    @media (max-width: 600px) {
    }
  }

  &__containerBalance {
    display: flex;
    justify-content: start;
    flex-direction: row;
    align-items: end;
    gap: 16px;
    margin-bottom: 48px;

    @media (max-width: 1100px) {
      flex-direction: column;
      align-items: center;
    }

    @media (max-width: 600px) {
      flex-direction: column;
      margin-bottom: 0;
    }
  }

  &__mainContentText {
    width: 640px;
    height: 40px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media (max-width: 1100px) {
      width: 344px;
      height: 41px;
    }
    @media (max-width: 600px) {
      width: 314px;
      height: 40px;
    }

    & p {
      width: 100%;
      z-index: 2;
      top: 0;
      color: #FEFEFE;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;

      @media (max-width: 1100px) {
        font-size: 20px;
      }

      @media (max-width: 600px) {
        font-size: 18px;
      }
    }

    &:after {
      z-index: 1;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      content: '';
      border-radius: 100px;
      background: radial-gradient(77.6% 91.12% at 50% 52.38%, #FFF 0%, rgba(255, 2, 2, 0.70) 15.5%, rgba(255, 2, 2, 0.00) 62.5%);
      filter: blur(8px);

      @media (max-width: 1100px) {
        border-radius: 53.636px;
        filter: blur(8.045454978942871px);
      }

      @media (max-width: 600px) {
        border-radius: 53.636px;
        filter: blur(8.045454978942871px);
      }
    }
  }

  &__title {
    color: #FFEBEB;
    text-align: center;
    text-shadow: 4px 4px 0px rgba(255, 0, 0, 0.40);
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;

    @media (max-width: 1100px) {
      text-shadow: 2.133px 2.133px 0px rgba(255, 0, 0, 0.40);
      font-size: 34px;
    }
    @media (max-width: 600px) {
      text-shadow: 1px 1px 0px rgba(255, 0, 0, 0.40);
      font-size: 24px;
    }
  }

  &__gradient {
    width: 100%;
    height: 40px;
    background: linear-gradient(
                    to bottom,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0.2) 20%,
                    rgba(0, 0, 0, 0.5) 50%,
                    rgba(0, 0, 0, 0.8) 80%,
                    rgba(0, 0, 0, 1) 100%
    );
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

.form {
  margin: 80px auto 0;
  max-width: 646px;

  &_processing {
    .form {
      &__input,
      &__submit,
      &__submit:hover {
        cursor: wait;
        opacity: 0.7;
      }
    }
  }

  @media (max-width: 600px) {
    margin-top: 40px;
    padding: 0 16px;
  }

  &__field {
    display: flex;
    align-items: flex-start;
    gap: 16px;

    @media (max-width: 600px) {
      display: block;
    }
  }

  &__label {
    color: var(--Neutral-100, #FFF);
    font-family: "Chakra Petch";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.56px;
    display: block;
    margin-bottom: 6px;
  }

  &__inputWrap {
    width: 100%;
    flex: 1 1 auto;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 0;
      top: 50%;
      left: 50%;
      width: 90%;
      height: 100%;
      transform: translate(-50%, -50%);
      box-shadow: 0px 0px 30px 0 rgba(255, 2, 2, 0.5);
    }
  }

  &__input {
    position: relative;
    z-index: 1;
    background-image: url(../../assets/image/inputs/input-bg.svg);
    background-position: center;
    background-size: 100% 100%;
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    display: block;
    width: 100%;
    padding: 22px 24px;
    color: var(--Neutral-100, #FFF);
    font-family: "Chakra Petch";
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.64px;

    &::placeholder {
      color: rgba(white, 0.4);
    }

    @media (max-width: 600px) {
      margin-bottom: 16px;
    }
  }

  &__submit {
    display: block;
    background: #FF0202;
    box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 0.25);
    color: var(--Neutral-100, #FFF);
    text-align: center;
    font-family: "Chakra Petch";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    cursor: pointer;
    transition: 200ms;
    border: none;
    flex: 0 0 auto;
    padding: 20px 30px;
    min-width: 144px;

    &:hover {
      opacity: 0.8;
    }
  }

  &__error {
    color: #FF0202;
    margin-top: 16px;
    text-shadow: 1px 1px 2px rgba(black, 0.9);
  }
}

