body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Chakra Petch', sans-serif;
  background: black;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
